import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {
  "title": "Production by year",
  "includeToc": true,
  "layout": "downloads",
  "description": "This dataset contains information on natural resource production volumes on Native American lands and federal lands and waters. We only provide production volumes for Native American lands by fiscal year.",
  "tags": ["Data", "Downloads", "Documentation", "Federal", "Production", "Indian", "Native American"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DownloadDataFile = makeShortcode("DownloadDataFile");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "/downloads/"
      }}>{`Downloads /`}</a></p>

    <h1 {...{
      "id": "Production-by-year",
      "style": {
        "position": "relative"
      }
    }}>{`Production by year`}</h1>
    <p>{`This dataset contains information on natural resource production volumes on Native American lands and federal lands and waters. `}<br /><br /></p>
    <p>{`Download data:`}</p>
    <DownloadDataFile linkType="DownloadCsv" dataSet="calendar_year_production.csv" mdxType="DownloadDataFile">
  Calendar year production
    </DownloadDataFile>
    <DownloadDataFile linkType="DownloadCsv" dataSet="fiscal_year_production.csv" mdxType="DownloadDataFile">
  Fiscal year production
    </DownloadDataFile>
    <DownloadDataFile linkType="DownloadXls" dataSet="all_production.xlsx" mdxType="DownloadDataFile">
Combined excel spreadsheet
    </DownloadDataFile>
    <h2 {...{
      "id": "Scope",
      "style": {
        "position": "relative"
      }
    }}>{`Scope`}</h2>
    <p>{`These datasets include natural resource production for federal lands, federal waters, and Native American lands. These datasets do not include privately owned lands or state-owned lands.`}</p>
    <br />
    <p>{`Federal production data is available by location. Native American data is only available at the national level to protect private and personally identifiable information.`}</p>
    <br />
    <p>{`The Department of the Interior’s Office of Natural Resources Revenue (ONRR) tracks and manages this data.`}</p>
    <ul>
      <li parentName="ul">{`Oil and Gas production is collected on Form ONRR-4054-B (Oil and Gas Operations Report, Part B). `}</li>
      <li parentName="ul">{`Solid mineral production is collected on Form ONRR-4430 (Solid Minerals Production and Royalty Report). `}</li>
      <li parentName="ul">{`Geothermal sales volumes are obtained from the Form ONRR-2014 (Report of Sales and Royalty Remittance).`}</li>
    </ul>
    <h2 {...{
      "id": "Data-publication",
      "style": {
        "position": "relative"
      }
    }}>{`Data publication`}</h2>
    <p>{`We update both the calendar year and fiscal year datasets on an annual basis. Final production numbers are not available for 3-4 months, so these datasets are updated 3-4 months after the end of the fiscal and calendar years.`}</p>
    <h2 {...{
      "id": "Annual-totals-and-monthly-totals",
      "style": {
        "position": "relative"
      }
    }}>{`Annual totals and monthly totals`}</h2>
    <p>{`The annual totals in this production by year data may not be equivalent to the sum of 12-month production totals (for a given calendar or fiscal year) found in the production by month data.
`}<a parentName="p" {...{
        "href": "/downloads/production-by-month/"
      }}>{`Production by month data`}</a>{`
While the production volume (amount of a commodity coming out of the ground) doesn't change, the way it is accounted for might. There are two main reasons why:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Operators may not report on time, meaning production for a past month might increase when the operator submits the report and ONRR updates the data.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The Bureau of Land Management may choose to change agreements and units after production. These changes may affect the allocation of the production volume, changing the data.`}</p>
      </li>
    </ul>
    <p>{`For example, changing an agreement retroactively may change the allocation of production among states, the federal government, and Native American tribes and individuals. As a result, the apportioned totals in the data would change.`}</p>
    <h2 {...{
      "id": "About-the-data",
      "style": {
        "position": "relative"
      }
    }}>{`About the data`}</h2>
    <h3 {...{
      "id": "Why-is-geothermal-energy-listed-so-many-times",
      "style": {
        "position": "relative"
      }
    }}>{`Why is geothermal energy listed so many times?`}</h3>
    <p>{`We can only compare production that is reported in the same unit. The standard unit of measurement for geothermal energy in the federal production dataset is kilowatt hours, but a few counties report it differently. We have separate charts for each of these counties because they report in nonstandard units:`}</p>
    <ul>
      <li parentName="ul">{`Dona Ana County, NM reports direct use of geothermal resources in `}<em parentName="li">{`million gallons`}</em>{`.`}</li>
      <li parentName="ul">{`Churchill County, NV reports direct use of geothermal resources in `}<em parentName="li">{`hundred gallons`}</em>{`.`}</li>
      <li parentName="ul">{`Lassen County, CA reports direct use of geothermal resources in `}<em parentName="li">{`million BTUs`}</em>{`.`}</li>
      <li parentName="ul">{`Lassen County, CA reports geothermal energy generation as `}<em parentName="li">{`other`}</em>{` (no unit).`}</li>
      <li parentName="ul">{`Lake County, CA; Sonoma County, CA; and Beaver County, UT report geothermal energy generation in `}<em parentName="li">{`thousand pounds`}</em>{`.`}</li>
      <li parentName="ul">{`Inyo County, CA reports sulfur from geothermal resources as `}<em parentName="li">{`long tons`}</em>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "A-note-about-Mixed-Exploratory-versus-Federal-categories-of-production",
      "style": {
        "position": "relative"
      }
    }}>{`A note about “Mixed Exploratory” versus “Federal” categories of production`}</h3>
    <p>{`To build our data visualizations, we aggregated production from two types of jurisdictions: “federal” and “mixed exploratory.”`}</p>
    <ul>
      <li parentName="ul">{`Federal production is production from federal lands and waters.`}</li>
      <li parentName="ul">{`“Mixed exploratory” is a temporary jurisdictional unit. It is used until production is proven on that location. BLM then creates a permanent unit with allocation schedules that may split that area between federal and other ownership. Payors then resubmit royalties based on the new unit allocations. These royalties are retroactive to the first production.`}</li>
    </ul>
    <p>{`The federal government rarely gets 100% of “mixed exploratory” volumes. These categories are disaggregated in the federal production dataset, downloadable on this page.`}</p>
    <h3 {...{
      "id": "Why-is-quartz-crystal-measured-in-ticketspounds",
      "style": {
        "position": "relative"
      }
    }}>{`Why is quartz crystal measured in “tickets/pounds”?`}</h3>
    <p>{`Quartz crystal is sometimes sold in relatively small quantities in gift shops and tourist attractions. In some cases, tourists can buy admission tickets to dig for their own minerals. ONRR may collect royalties on the admission tickets, not on the weight of minerals collected. Some minerals are sold by quality, not by weight.`}</p>
    <br />
The unit “tickets/pounds” shows quantities measured in both tickets sold and weight sold combined. The royalty amount for ticket transactions often does not meet ONRR's review threshold, so the specific quantities in weight may be unknown.
    <h2 {...{
      "id": "Data-dictionary",
      "style": {
        "position": "relative"
      }
    }}>{`Data dictionary`}</h2>
    <h3 {...{
      "id": "Fields-and-definitions",
      "style": {
        "position": "relative"
      }
    }}>{`Fields and definitions`}</h3>
    <p><strong parentName="p">{`Year`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Calendar Year:`}</strong>{` The period between January 1 and December 31 for a given year.`}</li>
      <li parentName="ul"><strong parentName="li">{`Fiscal Year:`}</strong>{`  The year the production occurred. The federal fiscal year runs from October 1 of the prior year through September 30 of the year being described. For example, Fiscal Year 2018 is between October 1, 2017, and September 30, 2018.`}</li>
    </ul>
    <p><strong parentName="p">{`Land Class`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Federal:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://sgp.fas.org/crs/misc/R42346.pdf"
        }}>{`Federal lands (PDF) `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` are owned by or under the jurisdiction of the federal government. Federal lands include: public domain lands, acquired lands, military acquired lands, and the `}<a parentName="li" {...{
          "href": "https://www.boem.gov/oil-gas-energy/leasing/outer-continental-shelf"
        }}>{`Outer Continental Shelf `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Native American:`}</strong>{`  Includes Tribal lands held in trust by the federal government for a tribe’s use, and allotments held in trust by the federal government for individual Native American use.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mixed Exploratory:`}</strong>{`  A temporary jurisdictional unit. It is used until production is proven on that location (see above).`}</li>
    </ul>
    <p><strong parentName="p">{`Land Category`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Onshore:`}</strong>{`  Situated or occurring on land.`}</li>
      <li parentName="ul"><strong parentName="li">{`Offshore:`}</strong>{`  Submerged lands located farther than three miles off a state’s coastline, or three marine leagues into the Gulf of Mexico off Texas and western Florida.`}</li>
    </ul>
    <p><strong parentName="p">{`State:`}</strong>{` The state where the production occurred.`}</p>
    <br />
    <p><strong parentName="p">{`County:`}</strong>{` The county where the production occurred.`}</p>
    <br />
    <p><strong parentName="p">{`FIPS Code:`}</strong>{` Federal Information Processing Standard (FIPS) code is a five-digit code which uniquely identifies counties and county equivalents in the U.S., certain U.S. possessions, and certain freely associated states. The first two digits are the FIPS state code and the last three are the county code within the state or possession.`}</p>
    <br />
    <p><strong parentName="p">{`Offshore Region:`}</strong>{` BOEM separates offshore areas into four regions: Gulf of Mexico, Atlantic, Pacific, and Alaska. For more information on offshore regions, including spatial boundaries, see BOEM's `}<a parentName="p" {...{
        "href": "https://www.boem.gov/oil-gas-energy/mapping-and-data"
      }}>{`maps and GIS data `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{`.`}</p>
    <br />
    <p><strong parentName="p">{`Product:`}</strong>{` Products are listed individually but may also fall into like categories.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Anhydrous Sodium Sulfate`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Borate Products`}</strong>{` (tons): include Borax-Anhydrous, Borax-Decahydrate, Borax-Pentahydrate, and Boric Acid`}</li>
      <li parentName="ul"><strong parentName="li">{`Borrow Sand and Gravel`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Brine Products:`}</strong>{` include Brine Barrels (converted to tons equivalent) and Magnesium Chloride Brine`}</li>
      <li parentName="ul"><strong parentName="li">{`Calcium Chloride`}</strong>{` (lbs)`}</li>
      <li parentName="ul"><strong parentName="li">{`Carbon Dioxide`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Cinders`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Clay`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Coal`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Coal-Bituminous-Raw`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Coal-Fines Circuit`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Other Coal Products`}</strong>{` (tons): include Leonardite and Coal Waste (Sub-Econ)`}</li>
      <li parentName="ul"><strong parentName="li">{`Copper`}</strong>{` (lbs)`}</li>
      <li parentName="ul"><strong parentName="li">{`Copper Concentrate`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Gas`}</strong>{` (Mcf)`}</li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Direct Use`}</strong>{` (hundreds of gallons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Direct Use`}</strong>{` (millions of gallons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Direct Use`}</strong>{` (millions of BTUs)`}</li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Electrical Generation`}</strong>{` (kilowatt hours)`}</li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Electrical Generation`}</strong>{` (thousands of pounds)`}</li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Electrical Generation`}</strong>{` (other)`}</li>
      <li parentName="ul"><strong parentName="li">{`Geothermal - Sulfur`}</strong>{` (long tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Gilsonite`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Gold and Silver Products`}</strong>{` (ozs): include Gold, Gold Placer, and Silver`}</li>
      <li parentName="ul"><strong parentName="li">{`Gold and Silver Products`}</strong>{` (tons): include Gold Ore`}</li>
      <li parentName="ul"><strong parentName="li">{`Gypsum`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Hardrock Products`}</strong>{` (tons): include Limestone (tons) and Wavellite (spcmns)`}</li>
      <li parentName="ul"><strong parentName="li">{`Humate`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Langbeinite`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Lead Concentrate`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Leonardite`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Mine Water`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Molybdenum Concentrate`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Muriate Of Potash-Granular`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Muriate Of Potash-Standard`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Oil`}</strong>{` (bbls)`}</li>
      <li parentName="ul"><strong parentName="li">{`Phosphate Raw Ore`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Potash`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Potassium Products`}</strong>{` (tons): include Manure Salts and Sylvite-Raw Ore`}</li>
      <li parentName="ul"><strong parentName="li">{`Potassium Sulphate-Standard`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Purge Liquor`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Quartz Crystal`}</strong>{` (tickets/lbs)`}</li>
      <li parentName="ul"><strong parentName="li">{`Salt`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Sand/Gravel`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Sand/Gravel-Cubic Yards`}</strong>{` (cyds)`}</li>
      <li parentName="ul"><strong parentName="li">{`Soda Ash`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Sodium Bi-Carbonate`}</strong>{` (tons)`}</li>
      <li parentName="ul"><strong parentName="li">{`Sodium Products`}</strong>{` (tons): include Anhydrous Sodium Sulfate, Sodium Bisulfite, Sodium Decahydrate, Sodium Sesquicarbonate, Sulfide, and Trona Ore`}</li>
      <li parentName="ul"><strong parentName="li">{`Zinc Concentrate`}</strong>{` (tons)`}</li>
    </ul>
    <p><strong parentName="p">{`Volume:`}</strong>{` Shows the production volume (amount produced). The units for volume are shown in the preceding field (Product).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      